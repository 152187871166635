import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { fetchBookingsSuccess } from 'lib/couples/actions/fetch-couples-bookings';
import { ICoupleBooking } from 'lib/couples/types';
import { Action, IEpicDeps } from 'lib/types';

export const fetchBookingsEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const {
        supplier: {
          supplier: { id },
        },
      } = state;

      const promise = authenticatedFetch(`/api/inbox/bookings/${id}`)
        .then((res) => res.json())
        .catch((err) => console.error(err));

      return from(promise).pipe(
        mergeMap(
          (
            bookingsResponse: { bookings: ICoupleBooking[]; stats: { total: number } } | undefined,
          ) => of(fetchBookingsSuccess(bookingsResponse)),
        ),
        catchError((error) =>
          of(appError({ error, feature: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START })),
        ),
      );
    }),
  );
