import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { ICoupleBooking } from 'lib/couples/types';

export const fetchBookings = () => ({
  type: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START,
});

export const fetchBookingsSuccess = (bookingsResponse?: {
  bookings: ICoupleBooking[];
  stats: { total: number };
}) => ({
  type: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_SUCCESS,
  payload: bookingsResponse,
});
