import { createSelector } from 'reselect';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithBadges } from '@bridebook/toolbox/src/i18n/features';
import { getScreenTypeByWidth } from '@bridebook/ui/src/utils/get-screen-type-by-width';
import { RoutingLocales, RoutingLocalesByCountryCode } from 'lib/i18n/constants';
import { findCountryCodeByLocale } from 'lib/i18n/utils';
import { getSupplierCountryCode as getSupplierCountry } from 'lib/supplier/selectors/contact';
import { IApplicationState } from 'lib/types';
import { createDeepEqualSelector } from 'lib/utils/selectors';

export const getLanguage = (state: IApplicationState) => state.i18n.language;

/**
 * @deprecated Try to generalize the logic instead.
 */
export const getIsUK = createSelector(
  [getSupplierCountry],
  (countryCode) => countryCode === CountryCodes.GB,
);

/**
 * @deprecated Try to generalize the logic instead.
 */
export const getIsDE = createSelector(
  [getSupplierCountry],
  (countryCode) => countryCode === CountryCodes.DE,
);

export const getSupplierCountryCode = createSelector(
  [getSupplierCountry],
  (countryCode) => countryCode,
);

export const getCountryCodeByAppLanguage = createSelector([getLanguage], (language) =>
  findCountryCodeByLocale(language),
);

export const getRoutingLocale = createSelector(
  [
    (state: IApplicationState) => Boolean(state.supplier.supplier),
    getSupplierCountryCode,
    getCountryCodeByAppLanguage,
  ],
  (hasSupplier, supplierCountryCode, appCountryCode) => {
    // For logged-in get the locale based on the supplier country code
    // For logged-out get the locale based on the i18n language selected
    const countryCode = hasSupplier ? supplierCountryCode : appCountryCode;
    return RoutingLocalesByCountryCode[countryCode] || RoutingLocales.UK;
  },
);

export const getSupplierLocale = createSelector(
  [getSupplierCountryCode],
  (supplierCountryCode) =>
    gazetteer.getMarketByCountry(supplierCountryCode, CountryCodes.GB).locale,
);

export const getDevice = (state: IApplicationState) => state.app.device;

/**
 * @warning This is Internet Explorer, not Ireland. 😮‍💨
 */
export const getIsIE = (state: IApplicationState) => state.app.device.isIE;
export const getPreviousPath = (state: IApplicationState) => state.app.previousPath;
export const getPathname = (state: IApplicationState) => state.app.pathname;

/**
 * Enabling CMS -> Settings -> Badges
 */
export const getIsBadgesSupported = createSelector([getSupplierCountry], (country) =>
  countriesWithBadges.includes(country),
);

export const getDeviceWidth = createSelector(getDevice, (device) => device.width);
/** Based on user-agent from server */
const getIsMobileUA = createSelector(getDevice, ({ isMobileUA }) => isMobileUA);
const getIsTabletUA = createSelector(getDevice, ({ isTabletUA }) => isTabletUA);

/**
 * Returns screen type (mobile, tablet, desktop) for hiding components from the DOM.
 * Screens are aligned with CSS breakpoints.
 */
export const getScreenType = createDeepEqualSelector(
  createSelector([getDeviceWidth, getIsMobileUA, getIsTabletUA], (width, isMobileUA, isTabletUA) =>
    getScreenTypeByWidth({ width, isMobileUA, isTabletUA }),
  ),
  (obj) => obj,
);
