import produce from 'immer';
import { Action } from 'redux';
import {
  FetchBookingsSuccessAction,
  FilterBookingsAction,
  ProcessBookingsByYearAction,
  SortBookingsAction,
  YourCouplesActionTypes,
} from 'lib/couples/action-types';
import { CouplesTableSort, ICouplesState } from 'lib/couples/types';

const initialState: ICouplesState = {
  bookings: null,
  total: 0,
  bookingsByYear: null,
  sortBy: CouplesTableSort.recentlyAdded,
  loaded: false,
  yearFilter: 'all',
};

const reducers: any = (draft: ICouplesState) => ({
  [YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_SUCCESS]: (action: FetchBookingsSuccessAction) => {
    if (action.payload) {
      const { bookings, stats } = action.payload;
      draft.bookings = bookings;
      draft.total = stats.total;
      draft.loaded = true;
    }
  },

  [YourCouplesActionTypes.PROCESS_BOOKING_BY_YEAR]: (action: ProcessBookingsByYearAction) => {
    draft.bookingsByYear = action.payload;
  },

  [YourCouplesActionTypes.SORT_BOOKINGS]: (action: SortBookingsAction) => {
    draft.sortBy = action.payload;
  },

  [YourCouplesActionTypes.FILTER_BOOKINGS]: (action: FilterBookingsAction) => {
    draft.yearFilter = action.payload;
  },
});

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: ICouplesState = initialState, action: Action): ICouplesState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
