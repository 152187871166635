import {
  fetchBookingsSuccess,
  filterBookings,
  processBookingsByYear,
  sortBookings,
} from 'lib/couples/actions';

export enum YourCouplesActionTypes {
  FETCH_COUPLES_BOOKINGS_START = '[Couples] Fetch your bookings start',
  FETCH_COUPLES_BOOKINGS_SUCCESS = '[Couples] Fetch your bookings success',
  PROCESS_BOOKING_BY_YEAR = '[Couples] Process bookings by year',
  SORT_BOOKINGS = '[Couples] Sort bookings',
  FILTER_BOOKINGS = '[Couples] Filter bookings',
  VIEW_INVITE_MESSAGE = '[Couples] Opened Invite message',
  RESET_INVITE_MESSAGE = '[Couples] Closed Invite message',
  FETCH_INVITE_MESSAGE_SUCCESS = '[Couples] Fetch Invite message success',
  SUPPLIER_OPENED_FORM_ANALYTICS = '[Analytics] [Couples] Supplier opened form',
  SUPPLIER_CLOSED_FORM_ANALYTICS = '[Analytics] [Couples] Supplier close form',
  BOOKING_CLICKED_ANALYTICS = '[Analytics] [Couples] Booking clicked',
  CONNECT_WITH_COUPLE_CLICKED_ANALYTICS = '[Analytics] [Couples] Connect with couple clicked',
}

export type FetchBookingsSuccessAction = ReturnType<typeof fetchBookingsSuccess>;
export type ProcessBookingsByYearAction = ReturnType<typeof processBookingsByYear>;
export type SortBookingsAction = ReturnType<typeof sortBookings>;
export type FilterBookingsAction = ReturnType<typeof filterBookings>;
