import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { extractSupplier, getSupplierUrl } from '@bridebook/toolbox';
import { getUrlCoupleSideLocale } from '@bridebook/toolbox/src/getSupplierUrl';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { ICoupleActivity } from 'lib/couples/types';
import { env } from 'lib/env';
import { getPhotos } from 'lib/photos/selectors';
import { Action, IApplicationState, State } from 'lib/types';
import { buildImgixUrl } from 'lib/utils';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function couplesAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const payload = action.payload || {};

  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();
  const genericProps = supplierPropertiesGeneric(state);

  const getThumbnail = (photos?: IPhoto[], photosCount?: number) => {
    if (!photos || !photosCount) return '';
    return buildImgixUrl(photos[0]);
  };

  const getContactCoupleEventPayload = (state: IApplicationState, eventData: ICoupleActivity) => {
    const sortedPhotos = getPhotos(state);
    const { supplier } = state.supplier;

    if (!supplier) return;

    const {
      countryCode,
      website: supplierWebsite,
      email: supplierEmail,
      phone: supplierPhone,
      publicId: supplierPublicId,
      detailedDescription: supplierDescription,
      photosCount,
    } = extractSupplier(supplier);

    const {
      weddingId,
      weddingPartnerName1,
      weddingPartnerName2,
      eventType: contactEventName,
    } = eventData;

    const supplierLocale = getUrlCoupleSideLocale(countryCode);

    const supplierProfileURL = `${env.COUPLESIDE_URL}/${supplierLocale}${getSupplierUrl(supplier)}`;

    return {
      category: 'Contact couple',
      weddingId,
      weddingPartnerName1,
      weddingPartnerName2,
      contactEventName,
      supplierEmail,
      supplierWebsite,
      supplierPhone,
      supplierDescription,
      supplierProfileURL,
      supplierPublicId,
      supplierThumbnailURL: getThumbnail(sortedPhotos, photosCount),
    };
  };

  switch (action.type) {
    case YourCouplesActionTypes.SUPPLIER_OPENED_FORM_ANALYTICS: {
      const { eventData } = payload;
      const contactCoupleGenericProps = getContactCoupleEventPayload(state, eventData);

      track({
        event: 'Triggered contact couple message',
        ...genericProps,
        ...contactCoupleGenericProps,
      });
      break;
    }

    case YourCouplesActionTypes.SUPPLIER_CLOSED_FORM_ANALYTICS: {
      const { eventData } = payload;
      const contactCoupleGenericProps = getContactCoupleEventPayload(state, eventData);

      track({
        event: 'Closed contact couple message',
        ...genericProps,
        ...contactCoupleGenericProps,
      });
      break;
    }
  }
}
