import { defaultLocale } from '@bridebook/toolbox/src/gazetteer';
import { AppState, IRouteChangeComplete, IRouteChangeStart } from 'lib/app/types';
import { Action } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { getPathnameFromUrl } from './utils/get-router-pathname';

const initialState: AppState = {
  baselineShown: false,
  darkEnabled: false,
  online: false,
  started: false,
  version: null,
  test: 0,
  pathname: '',
  query: {},
  asPath: '',
  previousPath: '',
  initialPath: '',
  isInitialPath: false,
  previousQuery: {},
  previousSearch: '',
  previousSearchQuery: {},
  apiUrl: '',
  scrollY: 0,
  scrollYSearch: 0,
  prevScrollY: 0,
  prevScrollSearchY: 0,
  device: {
    isLiveOnCDN: false,
    isMobile: false,
    isMobileUA: false,
    isTabletUA: false,
    isMobileSafari: false,
    isTablet: false,
    isDesktop: false,
    isLandscape: false,
    isIE: false,
    isChrome41: false,
    host: 'bridebook.co.uk',
    width: 0,
    height: 0,
    sessionId: '',
    analyticsContext: null,
    countryCode: null,
    serverLocale: defaultLocale,
  },
};

const reducer: (state: AppState, action: Action) => AppState = (
  state: AppState = initialState,
  action: Action,
): AppState => {
  switch (action.type) {
    case 'SET_APP_ONLINE':
      return { ...state, online: action.payload.online };

    case 'APP_STARTED':
      return { ...state, started: true };

    case 'ROUTE_CHANGE_COMPLETE': {
      const { url, query } = action.payload as IRouteChangeComplete['payload'];
      return {
        ...state,
        asPath: url,
        previousSearch: url.includes(UrlHelper.admin.search) ? url : state.previousSearch,
        previousSearchQuery: url.includes(UrlHelper.admin.search)
          ? query || {}
          : state.previousSearchQuery,
        pathname: getPathnameFromUrl(url),
        query: query || {},
      };
    }

    case 'UPDATE_PATHNAME': {
      const { url, query } = action.payload;
      return {
        ...state,
        pathname: getPathnameFromUrl(url),
        asPath: url,
        query: query || {},
      };
    }

    case 'ROUTE_CHANGE_START': {
      const { url, query } = action.payload as IRouteChangeStart['payload'];
      const initialPath = state.initialPath === '' ? url : state.initialPath;
      return {
        ...state,
        previousPath: url,
        initialPath,
        previousQuery: query || {},
        previousSearch: url.includes('/search') ? url : state.previousSearch,
      };
    }

    case 'RESIZE_WINDOW':
      return { ...state, device: { ...state.device, ...action.payload } };

    case 'ON_APP_VERSION':
      return { ...state, version: action.payload };

    case 'CLEAR_ALL_SEARCH':
      return { ...state, previousSearch: '' };

    default:
      return state;
  }
};

export default reducer;
